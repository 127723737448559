"use strict"

function cL() {
  var cl = document.getElementById('tagcloud');
  if (cl) {

    var anchors = cl.getElementsByTagName("a");
    for (var i = 0; i < anchors.length; i++) {
      var anchor = anchors[i];
      var clr = ("rgb(" + Math.floor(Math.random() * 256) + ',' + Math.floor(Math.random() * 256) + ',' + Math.floor(Math.random() * 256) + ')');
      var size = Math.floor(Math.random() * 8)
      anchor.style.color = clr;
      anchor.style.fontSize = "1." + size + "em";
    }
  }
}
function collapseComments() {
  var cont = document.querySelector('.comments-collapse');

  if (cont) {

    //            console.log('collapse');
    cont.addEventListener("click", function(e) {
      //                  console.log(e)
      var target = e.target;
      while (!target.classList.contains('comments') && target.parentNode) {
        target = target.parentNode;
      }
      target.classList.toggle('AtysCollapse-show');
      e.preventDefault();
    });

  }
}

document.addEventListener('DOMContentLoaded', function() {
  // scrol to main content
  collapseComments();

  cL();

  if (document.querySelector('[data-dismiss=modal]')) {
    document.querySelector('[data-dismiss=modal]').addEventListener("click", function(e) {
      //                        console.log("dismiss");
      window.location.hash = "";
    });
  }

  if (document.querySelector('.home'+'_form')) {
    document.querySelector('.home'+'_form').onsubmit=function(e){
      this.action="";
    }
  }
  if (document.querySelector('[name=form'+'_res]')) {
    document.querySelector('[name=form'+'_res]').onsubmit=function(e){
      this.action="";
    }
  }

  // unset autofocus input fields
  //findNavs();
});
